import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../services/image.service';
import { Book } from '../models/book.model';
import { DefaultProjectState } from '../store/default-project.state';
import { UserLocationState } from '../store/user-location.state';

enum CompressedImageSize {
  Xxs = '60x80',
  Xs = '120x160',
  S = '240x320',
  M = '480x800',
  L = '720x1280',
  Xl = '768x1280',
  Xxl = '1080x1920',
}

@Pipe({
  name: 'compressedImage',
  standalone: true,
})
export class CompressedImagePipe implements PipeTransform {
  constructor(
    private imageService: ImageService,
    private projectState: DefaultProjectState,
    private userLocationState: UserLocationState,
  ) {}

  transform(value: Book, size = CompressedImageSize.Xs): string {
    return this.imageService.getBookImage(
      value.uuid,
      value.version,
      this.projectState.defaultProject$.value.id,
      this.userLocationState.userLocation$.value.countryCode,
      'full_path',
      value.coverImagePath,
      size,
    );
  }
}

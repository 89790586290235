import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClientService } from './http-client.service';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private httpClientService: HttpClientService) {}

  generateQueryToken(path: string, time: number, clientToken: string): string {
    const value = `${clientToken}${path}${time}`;
    const hashedValue = CryptoJS.SHA256(value).toString();
    return hashedValue + '-' + time;
  }

  getBookImage(
    uuid: string,
    version: number,
    projectId: number,
    countryCode: string,
    pathType: 'full_path' | 'path',
    imagePath: string,
    size: string | null = null,
  ): string {
    const imageSize = size != null ? `&size=${size}` : '';
    imagePath = encodeURIComponent(imagePath.replace('../', ''));

    let fullPath = `/books/${uuid}/${version}/images?project_id=${projectId}&country=${countryCode}&${pathType}=${imagePath}${imageSize}`;
    const token = this.generateQueryToken('/v1' + fullPath, Date.now(), this.httpClientService.getClientCodeAndToken().clientToken);

    fullPath += '&token=' + token;

    return environment.booksBaseUrl + fullPath;
  }
}
